var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.product)?_c('router-link',{staticClass:"product-card__routerlink-wrap",attrs:{"to":`/${_vm.productLink}`}},[_c('v-row',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.calculateExplosion),expression:"calculateExplosion"}],ref:"refProductRow",staticClass:"product-card-row",attrs:{"no-gutters":""},on:{"mouseenter":function($event){_vm.isHover = true},"mouseleave":function($event){_vm.isHover = false}}},[_c('div',{staticClass:"product-background",style:({ 'background-color': _vm.color })}),_vm._v(" "),_c('div',{staticClass:"product-explosive",class:_vm.explosionClass,style:({
        'background-color': _vm.color,
        width: `${_vm.explosionRadiusCurrent * 2}px`,
        height: `${_vm.explosionRadiusCurrent * 2}px`,
        'margin-left': `-${_vm.explosionRadiusCurrent}px`,
        'margin-top': `-${_vm.explosionRadiusCurrent}px`,
      })}),_vm._v(" "),(_vm.withImage)?_c('v-col',{staticClass:"product-image d-none d-md-flex align-center justify-center",class:{ 'order-last': !_vm.reversed },attrs:{"cols":"12","md":"6"}},[_c('v-img',{attrs:{"src":_vm.productField('image'),"max-height":"360px","eager":"","contain":""},on:{"load":_vm.calculateExplosion}})],1):_vm._e(),_vm._v(" "),_c('v-col',{staticClass:"product-content pa-5 pa-md-10 d-flex flex-column"},[_c('div',{staticClass:"subtitle1 mb-4 corporateGrey--text"},[_vm._v("\n        "+_vm._s(_vm.productField("title"))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"subtitle2 mb-4"},[_vm._v(_vm._s(_vm.productField("subtitle")))]),_vm._v(" "),(_vm.withImage)?_c('div',{staticClass:"product-image d-md-none d-flex align-center justify-center"},[_c('v-img',{staticClass:"my-8",attrs:{"src":_vm.productField('image'),"max-height":"300px","contain":""},on:{"load":_vm.calculateExplosion}})],1):_vm._e(),_vm._v(" "),(!_vm.short)?_c('div',{staticClass:"body1 mb-4",domProps:{"innerHTML":_vm._s(_vm.productField('annotation'))}}):_vm._e(),_vm._v(" "),(!_vm.hideBadges)?_c('p',{staticClass:"mb-8"},_vm._l((_vm.categoriesList),function(categoryItem,index){return _c('badge',{key:index,attrs:{"text":categoryItem.title,"color":categoryItem.color}})}),1):_vm._e(),_vm._v(" "),_c('p',{staticClass:"mt-auto"},[_c('router-link',{staticClass:"btn-text product-card-details-btn",attrs:{"to":`/${_vm.productLink}`}},[_vm._v("\n          "+_vm._s(_vm.buttonDetailsTitle)+"\n          "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-chevron-right")])],1)],1)])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }