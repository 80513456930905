<template>
  <kk-link :link="`${field('link_url')}`" class="product-card__routerlink-wrap">
    <v-row
      ref="refIntroRow"
      v-resize="calculateExplosion"
      no-gutters
      class="product-card-row"
      @mouseenter="isHover = true"
      @mouseleave="isHover = false"
    >
      <div
        class="product-background"
        :style="{ 'background-color': field('color') }"
      />
      <!-- <div
        class="d-none d-md-block product-orbits"
        :class="{ 'from-image-left': reversed, 'from-image-right': !reversed }"
      >
        <div class="product-orbit product-orbit__mercury" />
        <div class="product-orbit product-orbit__venus" />
        <div class="product-orbit product-orbit__earth" />
        <div class="product-orbit product-orbit__mars" />
      </div> -->
      <div
        class="product-explosive"
        :class="explosionClass"
        :style="{
          'background-color': field('color'),
          width: `${explosionRadiusCurrent * 2}px`,
          height: `${explosionRadiusCurrent * 2}px`,
          'margin-left': `-${explosionRadiusCurrent}px`,
          'margin-top': `-${explosionRadiusCurrent}px`,
        }"
      />
      <v-col
        cols="12"
        md="6"
        class="product-image d-none d-md-flex align-center justify-center"
        :class="{ 'order-last': !reversed }"
      >
        <v-img
          :src="field('image')"
          max-height="360px"
          eager
          @load="calculateExplosion"
        />
      </v-col>
      <v-col class="product-content pa-5 pa-md-10 d-flex flex-column">
        <div class="subtitle1 mb-4 corporateGrey--text">
          {{ field("title") }}
        </div>
        <div class="product-image d-md-none d-flex align-center justify-center">
          <v-img
            class="my-8"
            :src="field('image')"
            max-height="300px"
            contain
            @load="calculateExplosion"
          />
        </div>

        <div class="subtitle2 mb-4" v-html="field('text')" />
        <p class="mt-auto">
          <kk-link
            :link="`${field('link_url')}`"
            class="btn-text product-card-details-btn"
          >
            {{ field("link_title") }}
            <v-icon color="primary">mdi-chevron-right</v-icon>
          </kk-link>
        </p>
      </v-col>
    </v-row>
  </kk-link>
</template>

<script>
import "./../ProductCard/ProductCard.scss";
import KkLink from "@/components/KkLink.vue";
export default {
  components: {
    KkLink,
  },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      explosionRadius: 0,
      isHover: false,
    };
  },
  computed: {
    field() {
      return (name) => this.sectionData.fields[name] || "";
    },
    reversed() {
      return this.field("image_left") === "1";
    },
    explosionRadiusCurrent() {
      return this.isHover ? this.explosionRadius : 0;
    },
    explosionClass() {
      return {
        "from-image from-image__left": this.reversed,
        "from-image from-image__right": !this.reversed,
      };
    },
  },
  updated() {
    this.$nextTick(() => {
      this.calculateExplosion();
    });
  },
  methods: {
    calculateExplosion() {
      if (!this.$refs.refIntroRow) return;
      const breakpointMobile = window.innerWidth < 1024;
      let w = this.$refs.refIntroRow.clientWidth;
      let h = this.$refs.refIntroRow.clientHeight;
      if (breakpointMobile) {
        w = w * 0.5;
        h = h - 300;
      } else {
        w = w * 0.75;
        h = h * 0.5;
      }
      this.explosionRadius = Math.sqrt(w * w + h * h) + 3;
    },
  },
};
</script>
