<template>
  <v-card tile elevation="0" color="primary" height="100%" class="footer"
    :style="`background-image: url(${baseUrl}img/background.svg)`">
    <v-container class="text-left pa-0" fluid>
      <v-row>
        <v-col class="mt-6 mt-md-0">
          <v-row>
            <v-col class="page-block flex-grow-1 flex-md-grow-0"
              ><span class="subtitle2 white--text">{{
                translations.company
              }}</span></v-col
            >
            <v-col class="page-block flex-grow-1 flex-md-grow-0"
              ><router-link
                class="white--text"
                style="white-space: nowrap"
                to="/about"
                >{{ translations.aboutUs }}</router-link
              ></v-col
            >
            <v-col class="page-block flex-grow-1 flex-md-grow-0"
              ><router-link class="white--text" to="/news">{{
                translations.news
              }}</router-link></v-col
            >
            <v-col
              v-if="translations.vacancies"
              class="page-block flex-grow-1 flex-md-grow-0"
              ><router-link class="white--text" to="/career">{{
                translations.vacancies
              }}</router-link></v-col
            >
            <v-col cols="12" class="pr-3 px-lg-0 page-block-wrapper">
              <router-link
                class="pt-5 pb-4 py-md-0 subtitle2 white--text products-left-margin"
                to="/products"
              >
                {{ translations.products }}
              </router-link>
              <router-link
                class="py-4 py-md-0 subtitle2 white--text products-left-margin"
                to="/clients"
              >
                {{ translations.clients }}
              </router-link>
              <router-link
                class="py-4 py-md-0 subtitle2 white--text products-left-margin"
                to="/contacts"
              >
                {{ translations.contacts }}
              </router-link>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider
        class="mt-10 mb-8"
        style="border-color: rgba(255, 255, 255, 0.12)"
      />
      <v-row justify="space-between">
        <v-col
          v-if="translations.email.length > 0"
          style="white-space: nowrap"
          class="contacts-block d-flex align-center"
        >
          <img class="mr-3" :src="`${baseUrl}img/mail_white.svg`" alt="" /><a
            class="white--text"
            :href="`mailto:${translations.email}?subject=${translations.emailSubject}`"
            >{{ translations.email }}</a
          >
        </v-col>
        <v-col
          v-if="translations.phone.length > 0"
          style="white-space: nowrap"
          class="contacts-block d-flex align-center"
        >
          <img class="mr-3" :src="`${baseUrl}img/phone_white.svg`" alt="" />
          <a class="white--text" :href="`tel:${translations.phone}`">{{
            translations.phone
          }}</a>
        </v-col>
        <v-col
          v-if="translations.address.length > 0"
          class="contacts-block address-block d-flex align-center"
        >
          <img class="mr-3" :src="`${baseUrl}img/placemark_white.svg`" alt="" />
          <a class="white--text" target="_blank" :href="translations.maplink">{{
            translations.address
          }}</a>
        </v-col>
        <v-col style="white-space: nowrap" class="contacts-block pull-right">
          <a
            v-for="(link, index) in data.links"
            :key="index"
            :href="link.fields.link"
          >
            <img
              width="30"
              height="30"
              :class="index != data.links.length - 1 ? 'mr-6' : ''"
              :src="link.fields.logo"
              alt=""
            />
          </a>
        </v-col>
      </v-row>
      <v-divider
        class="mt-8 mb-8"
        style="border-color: rgba(255, 255, 255, 0.12)"
      />
      <v-row>
        <v-col cols="12" md="5"
          ><span class="white--text"
            >© {{ translations.svyazcom }}, {{ startYear }}{{ endYear }}</span
          ></v-col
        >
        <v-col cols="12" md="7" class="text-md-right"
          ><router-link class="white--text" to="/privacy-policy">{{
            translations.personalData
          }}</router-link></v-col
        >
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    translations() {
      let obj = this.$store.getters.getMenu;
      let footer = this.$store.getters.getFooter;
      return {
        company: obj.children.find((x) => x.node_name == "company")?.fields
          .title,
        products: obj.children.find((x) => x.node_name == "products")?.fields
          .title,
        clients: obj.children.find((x) => x.node_name == "clients")?.fields
          .title,
        contacts: obj.children.find((x) => x.node_name == "contacts")?.fields
          .title,
        news: obj.children
          .find((x) => x.node_name == "company")
          ?.children.find((x) => x.node_name == "news")?.fields.title,
        vacancies: obj.children
          .find((x) => x.node_name == "company")
          ?.children.find((x) => x.node_name == "career")?.fields.title,
        aboutUs: obj.children
          .find((x) => x.node_name == "company")
          ?.children.find((x) => x.node_name == "about")?.fields.title,
        address: footer.fields.address || "",
        svyazcom: footer.fields.company || "",
        email: footer.fields.email || "",
        emailSubject: "From%20WWW",
        maplink: footer.fields.maplink,
        personalData: footer.fields.personal_data_title,
        phone: footer.fields.phone || "",
        startYear: footer.fields.company_start_year,
      };
    },
    startYear() {
      const year = this.translations.startYear;
      return year ? `${year}—` : "";
    },
    endYear() {
      return new Date().getFullYear();
    },
    data() {
      let footer = this.$store.getters.getFooter;
      return {
        links: footer.children,
      };
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  z-index: 5;
  background-repeat: no-repeat;
  background-size: 200% 200%;
  background-position: center;
}
::v-deep a:hover {
  color: rgba(255, 255, 255, 0.76) !important;
}
::v-deep a:hover img {
  opacity: 0.76;
}
.contacts-block {
  flex-basis: 100%;
}
.address-block {
  white-space: normal;
}
.page-block-wrapper {
  flex-basis: 100%;
  white-space: normal;
}
.products-left-margin {
  display: block;
  margin-left: 0px;
}
.products-left-margin:first-child {
  margin-left: 0px;
}
.page-block {
  flex-basis: 100%;
}
@media (min-width: 1024px) {
  .address-block {
    white-space: nowrap;
  }
  .page-block {
    flex-basis: 0;
  }
  .page-block-wrapper {
    white-space: nowrap;
  }
  .products-left-margin {
    display: inline;
    margin-left: 120px;
  }
  .products-left-margin:first-child {
    margin-left: 0px;
  }
}

@media (min-width: 1280px) {
  .contacts-block {
    flex-basis: 0;
  }
  .page-block-wrapper {
    flex-basis: 0;
    white-space: nowrap;
  }
  .pull-right {
    text-align: right;
  }
  .products-left-margin {
    margin-left: 120px !important;
  }
}
</style>
