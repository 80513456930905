<template>
  <v-row class="section justify-center">
    <v-col cols="12" md="6" lg="5" xl="4" class="flex-grow-0">
      <v-img eager :src="whatData.fields.image" @load="$emit('trigger-resize')" />
    </v-col>
    <v-col cols="12" md="6" lg="7" xl="5">
      <div class="heading2 section__title">
        {{ whatData.fields.title || "" }}
      </div>
      <div v-html="whatData.fields.text || ''" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "WhatIs",
  props: {
    whatData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
