<template>
  <div>
    <v-row>
      <v-col cols="12">
        <breadcrumbs class="no-marker px-0" :items="breadcrumbsItems" />
      </v-col>
    </v-row>
    <v-row class="section heading__section align-center justify-center">
      <v-col cols="12" md="" class="heading__content mr-md-2 mr-lg-4 mr-xl-0">
        <p class="mb-1 mb-md-2">
          <badge
            v-for="(categoryItem, index) in categoriesList"
            :key="index"
            :text="categoryItem?.title || ''"
            :color="categoryItem?.color || ''"
          />
        </p>
        <h1 class="mb-4 mb-md-8">
          {{ pageData.fields.title || "" }}
        </h1>
        <div class="mb-4 mb-md-8" v-html="pageData.fields.annotation" />
        <v-row class="d-flex d-md-none justify-center align-center">
          <v-col class="product-image__container">
            <v-img
              eager
              :src="pageData.fields.image"
              @load="$emit('trigger-resize')"
            />
          </v-col>
        </v-row>
        <v-row class="justify-start">
          <v-col class="flex-grow-0 pb-1">
            <v-btn depressed x-large color="primary" @click="showDialog = true">
              {{ pageData.fields.button_consult }}
            </v-btn>
          </v-col>
          <v-col class="flex-grow-0 pb-1">
            <v-btn
              depressed
              outlined
              x-large
              color="primary"
              @click="$emit('scroll-to-test')"
            >
              {{ pageData.fields.button_test }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-7">
          <v-col>
            <masked-icon-link
              :mask-icon="`${baseUrl}img/pdf.svg`"
              :title="pageData.products_page.fields.button_presentation || ''"
              @click="runPresentation"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md=""
        class="d-none d-md-block flex-grow-0 product-image__container"
      >
        <v-img eager :src="pageData.fields.image" @load="$emit('trigger-resize')" />
      </v-col>
    </v-row>

    <form-communication-modal v-model="showDialog" />

    <presentation-dialog v-model="showPresentationDialog" :product="pageData" />

    <presentation-dialog-fallback
      v-model="showPresentationFallbackDialog"
      :product="pageData"
    />
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Article/Breadcrumbs.vue";
import Badge from "@/components/Badge.vue";
import FormCommunicationModal from "@/components/FormCommunicationModal.vue";
import PresentationDialog from "@/components/PresentationPreview/PresentationDialog.vue";
import PresentationDialogFallback from "@/components/PresentationPreview/PresentationDialogFallback.vue";
import MaskedIconLink from "@/components/MaskedIconLink.vue";

export default {
  name: "Heading",
  components: {
    Breadcrumbs,
    Badge,
    FormCommunicationModal,
    PresentationDialog,
    PresentationDialogFallback,
    MaskedIconLink,
  },
  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      showPresentationDialog: false,
      showPresentationFallbackDialog: false,
    };
  },
  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_page.fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.products_page.fields.title || "",
          to: { name: "Products" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.fields.title || "",
          disabled: true,
        },
      ];
    },
    categoriesList() {
      if (this.pageData.pid) {
        return [this.$store.getters.getProductCategory(this.pageData.pid)];
      } else {
        return [];
      }
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
  methods: {
    runPresentation() {
      if ((this.pageData.fields.catchup_presentation?.items || []).length > 0) {
        this.showPresentationDialog = true;
      } else {
        this.showPresentationFallbackDialog = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.heading__section {
  padding-bottom: 40px;
  .heading__content {
    z-index: 5;
  }
  .product-image__container {
    position: relative;
    overflow: visible;
  }
}

@media (min-width: 1024px) {
  .heading__section {
    padding-bottom: 80px;
    .product-image__container {
      max-width: 450px;
    }
  }
}

@media (min-width: 1280px) {
  .heading__section {
    .product-image__container {
      max-width: 550px;
    }
  }
}

@media (min-width: 1440px) {
  .heading__section {
    .product-image__container {
      max-width: 600px;
    }
  }
}

@media (min-width: 1920px) {
  .heading__section {
    .product-image__container {
      max-width: 770px;
    }
  }
}
</style>
