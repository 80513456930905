<template>
  <div>
    <v-row class="section__title-row">
      <v-col class="text-center">
        <span class="heading2">{{ offerData.fields.title || "" }}</span>
      </v-col>
    </v-row>
    <v-row class="section__subtitle-row">
      <v-col class="text-center">
        <span class="quoteBase">{{ offerData.fields.subtitle || "" }}</span>
      </v-col>
    </v-row>
    <v-row class="section align-center">
      <v-col cols="12" lg="7">
        <v-img eager :src="offerData.fields.image" @load="$emit('trigger-resize')" />
      </v-col>
      <v-col cols="12" lg="5">
        <v-row>
          <div class="col-12" v-html="offerData.fields.text" />
          <v-col cols="12">
            <div class="corporateBlue-lighten4 pa-5 pa-md-10">
              <div class="subtitle1 section__subtitle">
                {{ offerData.fields.support_title }}
              </div>
              <div v-html="offerData.fields.support_text" />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Offer",
  props: {
    offerData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
