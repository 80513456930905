<template>
  <router-link
    v-if="product"
    :to="`/${productLink}`"
    class="product-card__routerlink-wrap"
  >
    <v-row
      ref="refProductRow"
      v-resize="calculateExplosion"
      no-gutters
      class="product-card-row"
      @mouseenter="isHover = true"
      @mouseleave="isHover = false"
    >
      <div class="product-background" :style="{ 'background-color': color }" />
      <!-- <div
        v-if="withImage"
        class="d-none d-md-block product-orbits"
        :class="{ 'from-image-left': reversed, 'from-image-right': !reversed }"
      >
        <div class="product-orbit product-orbit__mercury" />
        <div class="product-orbit product-orbit__venus" />
        <div class="product-orbit product-orbit__earth" />
        <div class="product-orbit product-orbit__mars" />
      </div> -->
      <div
        class="product-explosive"
        :class="explosionClass"
        :style="{
          'background-color': color,
          width: `${explosionRadiusCurrent * 2}px`,
          height: `${explosionRadiusCurrent * 2}px`,
          'margin-left': `-${explosionRadiusCurrent}px`,
          'margin-top': `-${explosionRadiusCurrent}px`,
        }"
      />
      <v-col
        v-if="withImage"
        cols="12"
        md="6"
        class="product-image d-none d-md-flex align-center justify-center"
        :class="{ 'order-last': !reversed }"
      >
        <v-img
          :src="productField('image')"
          max-height="360px"
          eager
          contain
          @load="calculateExplosion"
        />
      </v-col>
      <v-col class="product-content pa-5 pa-md-10 d-flex flex-column">
        <div class="subtitle1 mb-4 corporateGrey--text">
          {{ productField("title") }}
        </div>
        <div class="subtitle2 mb-4">{{ productField("subtitle") }}</div>
        <div
          v-if="withImage"
          class="product-image d-md-none d-flex align-center justify-center"
        >
          <v-img
            class="my-8"
            :src="productField('image')"
            max-height="300px"
            contain
            @load="calculateExplosion"
          />
        </div>

        <div
          v-if="!short"
          class="body1 mb-4"
          v-html="productField('annotation')"
        />
        <p v-if="!hideBadges" class="mb-8">
          <badge
            v-for="(categoryItem, index) in categoriesList"
            :key="index"
            :text="categoryItem.title"
            :color="categoryItem.color"
          />
        </p>
        <p class="mt-auto">
          <router-link
            :to="`/${productLink}`"
            class="btn-text product-card-details-btn"
          >
            {{ buttonDetailsTitle }}
            <v-icon color="primary">mdi-chevron-right</v-icon>
          </router-link>
        </p>
      </v-col>
    </v-row>
  </router-link>
</template>

<script>
import Badge from "@/components/Badge.vue";
import "./ProductCard.scss";
export default {
  components: {
    Badge,
  },
  props: {
    // ID продукта
    productId: {
      type: Number,
      required: true,
    },
    // отображать ли картинку доп. колонкой
    withImage: {
      type: Boolean,
      default: false,
    },
    // если true, то картинка отобразится слева, а не справа
    reversed: {
      type: Boolean,
      default: false,
    },
    // если true, то не отображать аннотацию
    short: {
      type: Boolean,
      default: false,
    },
    // если true, то не отображать аннотацию
    hideBadges: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      explosionRadius: 0,
      isHover: false,
    };
  },
  computed: {
    explosionRadiusCurrent() {
      return this.isHover ? this.explosionRadius : 0;
    },
    explosionClass() {
      return {
        "from-center": !this.withImage,
        "from-image from-image__left": this.withImage && this.reversed,
        "from-image from-image__right": this.withImage && !this.reversed,
      };
    },
    buttonDetailsTitle() {
      return this.$store.getters.getCommonFields.button_details;
    },
    categoriesList() {
      if (this.category) {
        return [this.category];
      } else if (this.product?.links?.product_category) {
        return this.product.links.product_category.map((categoryId) =>
          this.$store.getters.getProductCategory(categoryId)
        );
      } else {
        return [];
      }
    },
    color() {
      if (this.category) {
        return this.category.color || "white";
      } else if (this.product) {
        return this.product.fields.color || "white";
      } else {
        return "white";
      }
    },
    productField() {
      return (field) => (this.product ? this.product.fields[field] : "");
    },
    productLink() {
      return this.product ? this.product.node_name : "";
    },
    product() {
      return this.$store.getters.getProduct(this.productId);
    },
    category() {
      if (!this.product) return undefined;
      return this.$store.getters.getProductCategory(
        this.product.fields.category_id || 0
      );
    },
  },
  updated() {
    this.$nextTick(() => {
      this.calculateExplosion();
    });
  },
  methods: {
    calculateExplosion() {
      if (!this.$refs.refProductRow) return;
      const breakpointMobile = window.innerWidth < 1024;
      let w = this.$refs.refProductRow.clientWidth;
      let h = this.$refs.refProductRow.clientHeight;
      if (this.withImage) {
        if (breakpointMobile) {
          w = w * 0.5;
          h = h - 300;
        } else {
          w = w * 0.75;
          h = h * 0.5;
        }
      } else {
        w = w * 0.5;
        h = h * 0.5;
      }
      this.explosionRadius = Math.sqrt(w * w + h * h) + 3;
    },
  },
};
</script>
