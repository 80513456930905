<template>
  <div>
    <v-row class="section__title-row">
      <v-col class="text-center">
        <span class="heading2">{{ potentialData.fields.title || "" }}</span>
      </v-col>
    </v-row>
    <v-row class="section align-start">
      <v-col cols="12" lg="5" class="order-lg-1">
        <div
          class="corporateBlue-lighten4 pa-5 pa-md-10"
          v-html="potentialData.fields.text"
        />
      </v-col>
      <v-col cols="12" lg="7" class="graph__container order-lg-0">
        <div class="graph__title graph__title--vertical">
          {{ potentialData.fields.graph_title }}
        </div>
        <v-img
          eager
          :src="potentialData.fields.graph_image"
          class="ml-4 ml-sm-5 ml-md-8 ml-lg-8 ml-xl-9 mr-lg-10 mr-xl-16"
          @load="$emit('trigger-resize')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Potential",
  props: {
    potentialData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
@import "./Graphs.scss";
</style>
